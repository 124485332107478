import styled from 'styled-components';
import { Button, Icons, Tooltip } from '@components';

export const StyledLabelButton = styled.h6`
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;

export const StyledButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.vendor.colors.title};
  padding: 1.2rem;
  &:not(:disabled):hover {
    border-color: ${({ theme }) => theme.vendor.colors.title};
  }
  &:active {
    border-color: ${({ theme }) => theme.vendor.colors.title};
  }
  &:disabled {
    border-color: ${({ theme }) => theme.vendor.colors.title};
    color: ${({ theme }) => theme.vendor.colors.title};
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TooltipWrapperWithButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xl} 0;
`;

export const StyledTooltipInfo = styled(Tooltip)`
  text-align: left;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledMessage = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.vendor.colors.title};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  padding-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const StyledTextTooltip = styled.span`
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;

export const WrapperToolTip = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledTextBold = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;

export const WrapperIconQRCode = styled.div`
  width: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledScanQRCodeIcon = styled(Icons.ScanQRCodeIcon)`
  color: ${({ theme }) => theme.vendor.colors.title};
`;
