import { ButtonsFooter, Centering, Loader, MentionLegal } from '@components';
import { useForm, FormProvider } from 'react-hook-form';
import OfferForm from './OfferForm';
import { usePersistingState } from '@hooks';
import { IOffreCommerciale, IOffreCommercialeRequest, IOption } from '@types';
import {
  selectConfiguration,
  useAppSelector,
  useAppDispatch,
  setOffer,
  useSendOffreCommercialeMutation,
  selectSimulation,
} from '@store';
import { formatAsNumber, getLabelFromList } from '@utils';
import OfferPlaceForm from './OfferPlaceForm';
import { useEffect, useState } from 'react';
import * as messages from './messages';
import PurchaseOrder from './PurchaseOrder';

export const useOfferDescriptionReAuth = () => {
  const [, setShouldNotSubmitForm] = usePersistingState(
    'should-not-submit-form',
    false
  );
  return {
    reauth: (forceReAuth = true) => {
      if (!forceReAuth) {
        return;
      }
      setShouldNotSubmitForm(false);
    },
  };
};

const OfferDescription: React.FC = () => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const dispatch = useAppDispatch();

  const [sendOffreCommerciale, responseOffreCommerciale] =
    useSendOffreCommercialeMutation();
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
  const [errorPricing, setErrorPricing] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const simulation = useAppSelector(selectSimulation);

  const { isValid } = methods.formState;

  const [shouldNotSubmitForm, setShouldNotSubmitForm] = usePersistingState(
    'should-not-submit-form',
    false
  );
  const [materialCategoryLabel, setMaterialCategoryLabel] = usePersistingState(
    'material-category-label',
    ''
  );
  const [, , getListMaterials] = usePersistingState<IOption[] | undefined>(
    'list-materials',
    undefined
  );
  const configuration = useAppSelector(selectConfiguration);
  const { souscriptionId, mentionLegaleOffreCommerciale, listPlaces } = {
    ...configuration,
  };

  useEffect(() => {
    if (
      configuration?.isPassageDeContexte ||
      simulation?.simulation1 ||
      simulation?.simulation2
    ) {
      setIsBackButtonEnabled(false);
    }
  }, [configuration?.isPassageDeContexte, simulation]);

  useEffect(() => {
    if (
      responseOffreCommerciale?.isUninitialized ||
      responseOffreCommerciale?.isLoading
    ) {
      return;
    }
    const dataError =
      (responseOffreCommerciale?.error as { data: any }).data ?? [];
    if (dataError[0]) {
      setErrorPricing(dataError[0]?.error);
      setIsLoading(false);
    }
  }, [responseOffreCommerciale?.error]);

  useEffect(() => {
    if (
      responseOffreCommerciale?.isUninitialized ||
      responseOffreCommerciale?.isLoading
    ) {
      return;
    }
    setIsReadyToNavigate(true);
  }, [responseOffreCommerciale?.data]);

  const onSubmit = async () => {
    // clearPersonInformations();
    if (shouldNotSubmitForm) {
      setIsReadyToNavigate(true);
      return;
    }
    setIsLoading(true);
    const submitData = methods.getValues();
    const request = buildOfferRequest(submitData);
    if (!souscriptionId) {
      return;
    }
    sendOffreCommerciale(request);
    dispatch(
      setOffer({
        // On alimente le store (cf store.offer.offerSlice.ts)
        inputTypeLoan: request?.offre_commerciale?.produit_id,
        inputMaterialCategory:
          request?.offre_commerciale?.categorie_materiel_id,
        inputPurchaseAmount: request?.offre_commerciale?.montant_achat,
        inputDepositAmount: request?.offre_commerciale?.montant_apport,
        inputLoanAmount: request?.offre_commerciale?.montant_credit,
        inputTermNb: request?.offre_commerciale?.duree_souhaitee,
        inputPricing: request?.offre_commerciale?.tarification_id,
        inputCondition: request?.offre_commerciale?.modalite,
        inputDeferment: request?.offre_commerciale?.report,
        inputPlace: request?.offre_commerciale?.lieu_travaux,
        inputBorrowers: request?.offre_commerciale?.nombre_emprunteurs,
        materialCategoryLabel:
          getLabelFromList(
            request?.offre_commerciale?.categorie_materiel_id,
            getListMaterials() ?? []
          ) ?? '',
        purchaseOrderNumber: (
          request?.offre_commerciale?.numero_bon_de_commande ?? ''
        ).substring(0, 13),
      })
    );
  };

  const buildOfferRequest = (submitData: any): IOffreCommercialeRequest => {
    return {
      numero_souscription: souscriptionId ?? '',
      offre_commerciale: buildOffer(submitData),
    };
  };

  const buildOffer = (submitData: any): IOffreCommerciale => {
    return {
      produit_id: submitData?.inputTypeLoan,
      categorie_materiel_id: submitData?.inputMaterialCategory,
      montant_achat: formatAsNumber(submitData?.inputPurchaseAmount),
      montant_apport: formatAsNumber(submitData?.inputDepositAmount),
      montant_credit: formatAsNumber(submitData?.inputLoanAmount),
      duree_souhaitee: formatAsNumber(submitData?.inputTermNb),
      tarification_id: submitData?.inputPricing,
      modalite: submitData?.inputCondition,
      report: formatAsNumber(submitData?.inputDeferment),
      lieu_travaux: submitData?.inputPlace,
      nombre_emprunteurs: submitData?.inputBorrowers,
      numero_bon_de_commande: submitData?.purchaseOrderNumber,
    };
  };

  const onClickNext = () => {
    setIsSubmitted(true);
    if (!isValid) {
      return false;
    }
    onSubmit();
  };

  return (
    <Loader isLoading={!listPlaces} isTextInline message={messages.LOADER}>
      <Centering>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OfferForm
              onShouldNotSubmitForm={setShouldNotSubmitForm}
              setMaterialCategoryLabel={setMaterialCategoryLabel}
              errorPricing={errorPricing}
              setErrorPricing={setErrorPricing}
              isSubmitted={isSubmitted}
            />
            <OfferPlaceForm
              onShouldNotSubmitForm={setShouldNotSubmitForm}
              isSubmitted={isSubmitted}
            />
            <PurchaseOrder
              onShouldNotSubmitForm={setShouldNotSubmitForm}
              isSubmitted={isSubmitted}
            />

            <ButtonsFooter
              isBackVisible={isBackButtonEnabled}
              onClickNext={onClickNext}
              lockNavigate={!isValid}
              isLoading={isLoading}
              isReadyToNavigate={isReadyToNavigate}
              type="submit"
            />
          </form>
        </FormProvider>
        <MentionLegal htmlContent={mentionLegaleOffreCommerciale} />
      </Centering>
    </Loader>
  );
};

export default OfferDescription;
