import { Button } from '@components';
import { MAX_WIDTH_SIZE } from '@utils';
import styled, { css } from 'styled-components';
import { StyledLoader } from '../Button/styles';
import { VariantType } from '@components/Button/Button';

const getPrimary = (isLoading = false) => {
  return css`
    color: ${({ theme }) =>
      isLoading ? 'transparent' : theme.variables.colors.chartColors.green_500};
    border-color: ${({ theme }) =>
      theme.variables.colors.chartColors.green_500};
    background-color: transparent;
    border-width: 0.1rem;

    &:not(:disabled):hover {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.green_cymphony_700};
      color: ${({ theme }) => theme.variables.colors.chartColors.grey_700};
    }

    &:active {
      border-color: ${({ theme }) => theme.variables.colors.chartColors.black};
      color: ${({ theme }) => theme.variables.colors.chartColors.black};
    }

    &:disabled {
      border-color: ${({ theme }) =>
        isLoading
          ? theme.variables.colors.chartColors.dark_700
          : theme.variables.colors.chartColors.grey_100};
      color: ${({ theme }) =>
        isLoading
          ? 'transparent'
          : theme.variables.colors.chartColors.grey_100};
    }
  `;
};

const getSecondary = (isLoading = false) => {
  return css`
    color: ${({ theme }) =>
      isLoading ? 'transparent' : theme.variables.colors.chartColors.green_500};
    border-color: ${({ theme }) =>
      theme.variables.colors.chartColors.green_500};
    background-color: transparent;
    border-width: 0.1rem;


    &:hover {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.green_500};
      color: ${({ theme }) => theme.variables.colors.chartColors.green_500};
    }

    &:not(:disabled):hover {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.green_cymphony_700};
      color: ${({ theme }) =>
        theme.variables.colors.chartColors.green_cymphony_700};
      background-color: ${({ theme }) =>
        theme.variables.colors.chartColors.green_050};
    }

    &:active {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.green_500};
      color: ${({ theme }) => theme.variables.colors.chartColors.green_500};
    }

    &:disabled {
      border-color: ${({ theme }) =>
        isLoading
          ? theme.variables.colors.chartColors.green_500
          : theme.variables.colors.chartColors.green_500};
      color: ${({ theme }) =>
        isLoading
          ? 'transparent'
          : theme.variables.colors.chartColors.green_500};
    }
    ${StyledLoader} {
          & svg {
            color: ${({ theme }) =>
              theme.variables.colors.chartColors.green_500};
          }
  `;
};

const getVariantCss = (variant?: VariantType, isLoading?: boolean) => {
  switch (variant) {
    case 'primary':
      return getPrimary();
    case 'secondary':
      return getSecondary();
    default:
      return '';
  }
};

export const StyledButtonCymphony = styled(Button)<{}>`
  padding: 0.7rem ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  line-height: 22px;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  outline: none;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  border-style: solid;
  border-width: 0.2rem;
  font-weight: ${({ theme }) => theme.variables.fonts.types.bold};
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  word-break: normal;
  width: 100%;
  height: 100%;

  ${({ variant, isLoading }: { variant?: VariantType; isLoading?: boolean }) =>
    getVariantCss(variant, isLoading)}

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
  }
`;
