import { Button, Icons, Modal, Tooltip, ButtonCymphony } from '@components';
import ModalQRCode, { EModalState } from '../ModalQRCode/ModalQRCode';
import { InterruptedUpload } from '../Modal/alerts';
import {
  StyledButton,
  StyledScanQRCodeIcon,
  StyledTextTooltip,
  StyledTooltipInfo,
  WrapperIconQRCode,
  WrapperToolTip,
} from './styles';
import * as messages from './messages';
import { StyledMessage } from './styles';

export interface IProps {
  url: string;
  isVisible?: boolean;
  isButtonQRCodeLoading?: boolean;
  isButtonQRCodeClickable?: boolean;
  isLoading?: boolean;
  onSubmitModal?: () => void;
  onClick?: () => void;
  isButtonVisible?: boolean;
  onClickPoursuivre?: () => void;
  onClickArretParcoursSwitchDevice: () => void;
  onClickCancel: () => void;
  onClickTelechargementReussi: () => void;
  setModalState: React.Dispatch<React.SetStateAction<EModalState>>;
  modalState: EModalState;
  isUploadSwitchDeviceKO?: boolean;
  isSwitchDevice?: boolean;
}

const QRCodeBlock: React.FC<IProps> = ({
  url,
  onClick = () => null,
  isVisible = false,
  isLoading = false,
  isButtonVisible = false,
  isButtonQRCodeLoading = false,
  isButtonQRCodeClickable = true,
  onSubmitModal: onSubmit,
  onClickPoursuivre: onClickPoursuivre,
  onClickArretParcoursSwitchDevice: onClickArretParcoursSwitchDevice,
  onClickCancel: onClickCancel,
  onClickTelechargementReussi: onClickTelechargementReussi,
  setModalState,
  modalState,
  isUploadSwitchDeviceKO,
  isSwitchDevice,
}) => (
  <>
    {isButtonVisible && (
      <StyledTooltipInfo
        hasArrowDown={false}
        skin="primaryCymphony"
        isVisible={isButtonVisible}
      >
        <WrapperToolTip>
          <StyledTextTooltip>{messages.INFO_SCAN_DOCUMENTS}</StyledTextTooltip>
          <StyledButton
            variant="secondary"
            onClick={onClick}
            isLoading={isButtonQRCodeLoading}
            isDisabled={isButtonQRCodeClickable}
          >
            <>
              <WrapperIconQRCode>
                <StyledScanQRCodeIcon />
              </WrapperIconQRCode>
              <StyledMessage>{messages.BOUTON_QR_CODE}</StyledMessage>
            </>
          </StyledButton>
        </WrapperToolTip>
      </StyledTooltipInfo>
    )}
    <ModalQRCode
      url={url}
      isVisible={isVisible}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClickPoursuivre={onClickPoursuivre}
      onClickArretParcoursSwitchDevice={onClickArretParcoursSwitchDevice}
      onClickCancel={onClickCancel}
      modalState={modalState}
      setModalState={setModalState}
      onClickTelechargementReussi={onClickTelechargementReussi}
    />
    <Modal isVisible={isUploadSwitchDeviceKO && isSwitchDevice}>
      <InterruptedUpload />
    </Modal>
  </>
);

export default QRCodeBlock;
