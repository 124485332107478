import styled from 'styled-components';
import {
  Card,
  Subtitle,
  Icons,
  Tooltip,
  Button,
  Title,
  Loader,
} from '@components';
import { MAX_WIDTH_SIZE } from '@utils';

export const StyledCard = styled(Card.Wrapper)`
  padding-bottom: 2.9rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    &:last-child: {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledSubtitle = styled(Subtitle)`
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  display: inline-block;
`;

export const StyledMandatory = styled.span`
  display: flex;
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
`;

export const WrapperSelect = styled.div`
  display: flex;
  width: 100%;
  max-width: 50rem;
  margin-bottom: : ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-right: : ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-left: : ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const WrapperReminder = styled.p<{
  isMobileDevice: boolean;
}>`
  padding: 0;
  margin: 0 0
    ${({ isMobileDevice, theme }) =>
      isMobileDevice ? '0' : theme.variables.layout.spacing.spacing_s}
    0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;

export const WrapperUpload = styled.div<{
  isUploadMultiple?: boolean;
  isMobileDevice: boolean;
  isDouble?: boolean;
}>`
  display: flex;
  max-width: ${({ isUploadMultiple, isMobileDevice, isDouble }) =>
    isUploadMultiple || (isMobileDevice && isDouble) ? '100%' : '48rem'};
  gap: 2.4rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    flex-direction: column;
  }
`;

export const WrapperDownloadOneOrMoreFiles = styled.p`
  padding: 0;
  margin: 0 0 ${({ theme }) => theme.variables.layout.spacing.spacing_xxs} 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;

export const WrapperButtonLine = styled.div`
  max-width: 48rem;
  height: ${({ theme }) => theme.variables.layout.spacing.spacing40px};
  display: flex;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    flex-direction: column;
    justify-content: center;
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  }
`;
export const WrapperButton = styled.div`
  margin-left: auto;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-left: 0;
  }
`;

export const StyledPlusCircleOutlinesIcon = styled(
  Icons.PlusCircleOutlinesIcon
)`
  width: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  margin-right: 1rem;
`;

export const WrapperReminderDoubleUpload = styled.p`
  padding: 0;
  margin: 0 0 ${({ theme }) => theme.variables.layout.spacing.spacing_s} 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;

export const WrapperCheckBox = styled.div`
  margin: 0.6rem 0 0.6rem 0;
`;

export const WrapperCheckBoxHasHisRib = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const BlockRadioButton = styled.div`
  margin-right: 2.5rem;
`;

export const BlockRadio = styled.div`
  display: flex;
  margin-left: 5.8rem;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-left: 0;
  }
`;

export const WrapperSent = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

export const StyledSentText = styled.span`
  display: flex;
  font-weight: bold;
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const StyledSentIcon = styled(Icons.CheckCircleIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
`;

export const StyledSentIconSwitchDevice = styled(Icons.CheckCircleIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
  width: 4rem;
`;

export const StyledCrossIcon = styled(Icons.CrossCircleOutlinesIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  width: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xl} 0;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin: 0;
  }
`;
export const StyledMessage = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
`;

export const StyledText = styled.p`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  letter-spacing: 0;
  margin: 0;
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    padding-bottom: 0;
    padding-top: 0;
  }
`;
export const StyledTitle = styled(Title)`
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }
`;

export const WrapperCardDocumentSent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    flex-direction: column;
    /* Largeur: total - les 2 icones - leurs marges */
    width: calc(
      100% - 4.8rem - 4 *
        ${({ theme }) => theme.variables.layout.spacing.spacing_xs}
    );
  }
`;

export const StyledButtonFinish = styled(Button)`
  justify-content: center;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
    min-height: 4.8rem;
  }
`;
export const StyledDescription = styled.p`
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const TextParagraph = styled.p`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  letter-spacing: 0;
  margin: 0;
  text-align: center;
`;
export const WrapperMessageUploading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledSubtitleUploading = styled(Subtitle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
`;

export const StyledTooltipInfo = styled(Tooltip)`
  text-align: left;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
export const StyledTextTooltip = styled.span`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;

export const StyledLoader = styled(Loader)<{ isLoading: boolean }>`
  ${({ isLoading }) => {
    if (!isLoading) {
      return `top: 0;
      bottom: 0;
      left: 0;
      right: 0;`;
    }
    return `position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: (100vh - 800px);
      min-height: (100vh - 400px);
      max-width: 100%;
      min-width: 100%;
      overflow: hidden;
      opacity: 1;
      z-index: 20;

      & > .app {
        filter: blur(0.35rem);
      }
      
      & > div {
        z-index: 20;
      }`;
  }};
`;
