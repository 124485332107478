import {
  BaseQueryFn,
  MutationDefinition,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import {
  UseLazyQuery,
  UseMutation,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect } from 'react';
import {
  resetSynchronize,
  responseSynchronize,
  selectSynchronize,
  useAppDispatch,
  useAppSelector,
} from '@store';
import { ISynchronize, ISynchronizeFullyTyped, SynchronizeName } from '@types';

// TStoredData => Type de l'élément stocké dans le store (par exemple emprunteur du slice person dans le cas d'une synchro de l'emprunteur)
// TRequest => Type de l'objet attendu par le endpoint de l'api slice
// TResponse => Type de l'objet renvoyé par le backend
const useSynchronize = <TStoredData, TRequest, TResponse>({
  synchronizeName,
  getStoredData,
  validate,
  validateStoreSynchronization,
  useSendMutation,
}: {
  // Clé d'identification de l'appel de synchro => obligatoire (Ex. patch enmprunteur de la page ou patch coemprunteur de la page info)
  synchronizeName: SynchronizeName;
  // Elément stocké dans le store (par ex. emprunteur du slice person lors de l'update de l'emprunteur)
  getStoredData?: () => TStoredData;
  // Vérification pendant synchro: Règles de validation de la réponse de l'appel (par exemple la date du RIB identique entre l'appel et la réponse)
  validate?: ({
    request,
    response,
  }: {
    request?: TRequest;
    response?: TResponse;
  }) => boolean;
  // Vérification post synchro: Règles de validation entre l'objet mémorisé et la réponse (par exemple emprunteur dans le slice Person et la réponse dans le slice de synchronisation)
  validateStoreSynchronization?: (args: {
    storedData: TStoredData;
    response: TResponse;
  }) => boolean;
  // hook initial d'envoi/réception API
  useSendMutation:
    | UseMutation<MutationDefinition<TRequest, any, any, TResponse>>
    | UseLazyQuery<QueryDefinition<TRequest, any, never, TResponse>>
    | UseLazyQuery<
        QueryDefinition<
          TRequest,
          BaseQueryFn,
          never,
          TResponse,
          'souscriptionApi'
        >
      >;
}) => {
  const NUMBER_MAX_OF_SYNCHRONIZE_ATTEMPTS = 5;

  const [patch, response] = useSendMutation();
  const synchronizedSlice = useAppSelector(selectSynchronize);

  const getSynchronizedData = () => {
    for (const currentSynchronized of synchronizedSlice ?? []) {
      if (currentSynchronized?.name === synchronizeName) {
        return currentSynchronized;
      }
    }
    return {} as ISynchronize;
  };
  const synchronizedData = getSynchronizedData() as ISynchronizeFullyTyped<
    TRequest,
    TResponse
  >;

  const dispatch = useAppDispatch();

  //Comparaison de la requête et de la réponse
  const isPersonLastAttemptSynchronizedSuccess = ({
    response,
  }: {
    response?: TResponse;
  }): boolean => {
    if (!validate) {
      return true;
    }
    return validate({
      request: synchronizedData?.request,
      response: response,
    });
  };

  const synchronizeWithReattempts = ({
    response,
  }: {
    response?: TResponse;
  }) => {
    if (!response) {
      return;
    }
    dispatch(
      responseSynchronize({
        name: synchronizeName,
        response,
      })
    );
    if (
      !isPersonLastAttemptSynchronizedSuccess({
        response,
      }) &&
      synchronizedData?.request
    ) {
      if (
        (synchronizedData.attemptCount ?? 0) <
        NUMBER_MAX_OF_SYNCHRONIZE_ATTEMPTS
      ) {
        send(synchronizedData?.request);
      } else {
        dispatch(
          responseSynchronize({
            name: synchronizeName,
            isAttempting: false,
            attemptCount: 0,
          })
        );
      }
    } else {
      dispatch(
        responseSynchronize({
          name: synchronizeName,
          isSuccess: true,
          isAttempting: false,
          attemptCount: 0,
        })
      );
    }
  };

  useEffect(() => {
    if (!response?.data) {
      return;
    }
    synchronizeWithReattempts({ response: { ...response?.data } });
  }, [response]);

  const send = (request: TRequest) => {
    dispatch(
      resetSynchronize({
        synchronizeName,
        request,
        response: request,
      })
    );
    patch(request);
  };

  const refreshSynchro = () => {
    if (!synchronizedData?.isSuccess && synchronizedData?.request) {
      send(synchronizedData?.request);
    }
  };

  const validateSyncEnded = (): boolean => {
    if (synchronizedData?.isAttempting || !synchronizedData?.isSuccess) {
      return false;
    }
    if (!validateStoreSynchronization || !getStoredData || !getStoredData()) {
      return true;
    }
    return validateStoreSynchronization({
      storedData: getStoredData(),
      response: synchronizedData?.response,
    });
  };

  return {
    send, // Envoi de la modification au backend
    response, // réponse du backend
    refreshSynchro, // relance de synchro si nécessaire après chargement de l'app (ex. reprise ping)
    validateSyncEnded, // validation après la synchro terminée
    synchronizedData, // données de synchronisation
  };
};

export default useSynchronize;
