import React from 'react';
import { WarningIcon } from '@icons';
import {
  StyledWrapperWarningMessage,
  StyledWarningMessage,
  StyledAdviceIcon,
  Wrapper,
} from './styles';

export type SkinType =
  | 'advice'
  | 'help'
  | 'info'
  | 'deleteCustomerInfo'
  | 'warning'
  | 'primary'
  | 'caution'
  | 'secondary'
  | 'primaryCymphony';

export interface ITooltipProps {
  children?: React.ReactNode;
  isVisible?: boolean;
  skin?: SkinType;
  hasArrowDown?: boolean;
  className?: string;
  icon?: React.FC;
}

const getIcon = ({
  skin = 'advice',
  icon,
}: {
  skin?: SkinType;
  icon?: React.FC;
}) => {
  if (icon) {
    return icon;
  }
  if (skin === 'warning') {
    return WarningIcon;
  }
};

const isIconVisible = ({
  skin = 'advice',
  icon,
}: {
  skin?: SkinType;
  icon?: React.FC;
}) => {
  return (
    !!getIcon({
      skin,
      icon,
    }) ||
    skin === 'advice' ||
    skin === 'info' ||
    skin === 'primary'
  );
};
const Tooltip = ({
  children,
  isVisible = false,
  hasArrowDown = true,
  skin = 'advice',
  className = '',
  icon,
}: ITooltipProps): React.ReactElement => {
  return (
    <Wrapper isVisible={isVisible}>
      <StyledWrapperWarningMessage
        skin={skin}
        className={'ph-tooltip ' + className}
        hasArrowDown={hasArrowDown}
        isVisible={isVisible}
      >
        {
          <>
            {isIconVisible({ icon, skin }) && (
              <StyledAdviceIcon skin={skin} as={getIcon({ icon, skin })} />
            )}
          </>
        }
        <StyledWarningMessage>{children}</StyledWarningMessage>
      </StyledWrapperWarningMessage>
    </Wrapper>
  );
};

export default Tooltip;
