import { useState, useEffect } from 'react';
import { ButtonsFooter, Centering, Icons, Loader } from '@components';
import { EvidenceType, IOcr, IPieceJustificative } from '@types';
import BlockOcr from './BlockOcr';
import {
  useBorrowers,
  usePersistingState,
  useParcoursType,
  useDetectMobileDevice,
  useEffectOnInitApp,
} from '@hooks';
import { detectMobileDevice, detectTabletDevice } from '@utils';
import {
  selectConfiguration,
  personTypes,
  selectOffer,
  selectPerson,
  useAppSelector,
  useAppDispatch,
  selectSwitchDevice,
  useSendEmprunteurMutationSynchronized,
  useSendBankInformationsMutationSynchronized,
  selectDocumentsSynchronization,
  updateSwitchDevice,
} from '@store';
import {
  StyledMessage,
  StyledTooltip,
  TooltipWrapper,
  StyledButtonFinish,
  StyledDescription,
  StyledTitle,
  StyledTextTooltip,
  StyledTooltipInfo,
  StyledLoader,
} from './styles';
import * as messages from './messages';
import QRCodeBlock from './QRCode/QRCode';
import FinishUploadMobile from './FinishUploadMobile/FinishUploadMobile';
import useSwitchDevice from './useSwitchDevice';
import useBlocksState, {
  EEvidenceBlocksTypeNames,
  ETypeEmprunteurUploaded,
} from './useBlocksState';
import useSynchronizeDocuments from './useSynchronizeDocuments';

export interface IProps {
  currentBlockName?: string;
}

const Documents: React.FC<IProps> = ({ currentBlockName }) => {
  const dispatch = useAppDispatch();
  const offer = useAppSelector(selectOffer);
  const person = useAppSelector(selectPerson);
  const { parametres_ocr, souscriptionId, isAppReinitialized } =
    useAppSelector(selectConfiguration);
  const {
    isQrActivated,
    isListenerTriggering,
    isListenerInitialized,
    isUploadSwitchDeviceKO,
    isFinishUploadMobile,
  } = useAppSelector(selectSwitchDevice);
  const { synchronizedFiles } = useAppSelector(selectDocumentsSynchronization);

  const { hasCoborrower } = useBorrowers();
  const { isMobileScreenSize } = useDetectMobileDevice();
  const { isParcoursNominal, isParcoursSwitchDevice } = useParcoursType();

  // Refresh update of borrowers/bank informations after reco ping if needed
  useSendEmprunteurMutationSynchronized({
    blockName: EvidenceType.JDID,
    borrowerType: ETypeEmprunteurUploaded.BORROWER,
  });
  useSendEmprunteurMutationSynchronized({
    blockName: EvidenceType.JDDO,
    borrowerType: ETypeEmprunteurUploaded.BORROWER,
  });
  useSendBankInformationsMutationSynchronized({});
  useSendEmprunteurMutationSynchronized({
    blockName: EvidenceType.JDID,
    borrowerType: ETypeEmprunteurUploaded.COBORROWER,
  });
  useSendEmprunteurMutationSynchronized({
    blockName: EvidenceType.JDDO,
    borrowerType: ETypeEmprunteurUploaded.COBORROWER,
  });

  const {
    urlQRCode,
    isModalQRCodeVisible,
    isLoadingQRCodeButton,
    onClickFinishButton,
    onClickCancel,
    onClickArretParcoursSwitchDevice,
    onClickQRCodeButton,
    onClickTelechargementReussi,
    modalState,
    setModalState,
    setIsSwitchDeviceRelaunch,
    initParcoursSwitchDevice,
  } = useSwitchDevice();
  const {
    sendDocumentsSynchronization,
    isLoading: loadingDocumentsSynchronization,
  } = useSynchronizeDocuments();
  const { blocksState: blocksModel, isAllPiecesJustificativesValidated } =
    useBlocksState();

  const [ocrParameters, setOcrParameters] = useState<IOcr | null>(null);
  const [mandatoryCountBlock, setMandatoryCountBlock] = useState(0);
  const [isAnalyseSending, setIsAnalyseSending] = useState(false);

  const [currentBlock, setCurrentBlock] = usePersistingState('currentBlock', {
    name: currentBlockName,
    index: 0,
  });
  const [mandatoryBlockChecked, setMandatoryBlockChecked] = usePersistingState(
    'mandatoryBlockChecked',
    0
  );
  const [selectedBlock, setSelectedBlock] = usePersistingState<
    string | undefined
  >('block-ocr-selected', undefined);

  useEffectOnInitApp(() => {
    if (!souscriptionId || !parametres_ocr) {
      return;
    }
    sendDocumentsSynchronization();
  }, [, parametres_ocr]);

  useEffect(() => {
    const paramsOcr = parametres_ocr?.parametres;
    if (!paramsOcr) {
      return;
    }
    // Get OCR Parameters
    setOcrParameters(paramsOcr as IOcr);
    handleCountMandatory(paramsOcr as IOcr);
  }, [parametres_ocr?.parametres]);

  useEffect(() => {
    if (!isParcoursSwitchDevice()) {
      return;
    }
    initParcoursSwitchDevice();
  }, [, isParcoursSwitchDevice()]);

  useEffect(() => {
    if (
      !synchronizedFiles?.switch_device_en_cours ||
      (isListenerTriggering === false && isListenerInitialized === false)
    ) {
      return;
    }
    dispatch(
      updateSwitchDevice({
        isListenerTriggering: false,
        isListenerInitialized: false,
      })
    );
  }, [synchronizedFiles]);

  useEffect(() => {
    if (
      !synchronizedFiles?.switch_device_en_cours ||
      isListenerInitialized ||
      isListenerTriggering ||
      isParcoursSwitchDevice()
    ) {
      return;
    }
    setIsSwitchDeviceRelaunch(true);
  }, [synchronizedFiles, isListenerTriggering, isListenerInitialized]);

  const { ocrKo } = person?.emprunteur ?? '';
  const isMobileDevice = detectMobileDevice() || detectTabletDevice();

  const handleCountMandatory = (data: IOcr) => {
    setMandatoryCountBlock(0);
    Object.keys(data.blocks).forEach((blockName) => {
      if (blockName !== EEvidenceBlocksTypeNames.BLOCK_COMMANDE) {
        if (
          blockName !== EEvidenceBlocksTypeNames.BLOCK_COBORROWER &&
          !hasCoborrower()
        ) {
          const block = data.blocks[blockName];
          Object.keys(block.evidenceBlocks).forEach(
            (pieceJustificativeName) => {
              const evidenceBlock =
                block.evidenceBlocks[pieceJustificativeName];
              if (
                !(
                  evidenceBlock.hideUnderThreshold &&
                  offer.inputLoanAmount <= data.overdraftThreshold
                )
              ) {
                if (
                  data.blocks[blockName].evidenceBlocks[pieceJustificativeName]
                    .mandatory
                ) {
                  setMandatoryCountBlock((preValue) => preValue + 1);
                }
              }
            }
          );
        }
      }
    });
  };

  const handleValidate = (block: IPieceJustificative) => {
    // The next block becomes the current block
    const nextBlock = {
      name: '',
      index: currentBlock.index + 1,
    };
    setCurrentBlock(nextBlock);
    // Update button state
    if (block?.isMandatory) {
      setMandatoryBlockChecked(mandatoryBlockChecked + 1);
    }
  };

  return (
    <>
      {isFinishUploadMobile ? (
        <FinishUploadMobile />
      ) : (
        <Centering>
          <StyledTitle>{messages.TITLE_PAGE_DOCUMENT}</StyledTitle>

          <StyledLoader
            isLoading={
              loadingDocumentsSynchronization &&
              (isAppReinitialized || !offer?.inputLoanAmount)
            }
          >
            <QRCodeBlock
              isUploadSwitchDeviceKO={isUploadSwitchDeviceKO}
              isSwitchDevice={isParcoursSwitchDevice()}
              url={urlQRCode ?? ''}
              isVisible={isModalQRCodeVisible}
              isButtonQRCodeLoading={isLoadingQRCodeButton}
              isButtonQRCodeClickable={isAnalyseSending}
              isButtonVisible={
                isParcoursNominal() &&
                !isMobileScreenSize() &&
                isQrActivated &&
                !isAllPiecesJustificativesValidated &&
                !ocrKo &&
                !detectTabletDevice()
              }
              onClick={() => onClickQRCodeButton()}
              onClickArretParcoursSwitchDevice={() =>
                onClickArretParcoursSwitchDevice()
              }
              onClickCancel={onClickCancel}
              modalState={modalState}
              setModalState={setModalState}
              onClickTelechargementReussi={onClickTelechargementReussi}
            />
            {!isMobileScreenSize() && (
              <>
                <StyledTooltipInfo
                  skin="warning"
                  isVisible
                  icon={Icons.WarningIcon}
                  hasArrowDown={false}
                >
                  <StyledTextTooltip>{messages.SCAN}</StyledTextTooltip>
                </StyledTooltipInfo>
                <StyledTooltipInfo
                  skin="advice"
                  isVisible
                  icon={Icons.InfoGlyphIcon}
                  hasArrowDown={false}
                >
                  <StyledTextTooltip>{messages.UPLOAD_LATER}</StyledTextTooltip>
                </StyledTooltipInfo>
              </>
            )}
            {blocksModel &&
              blocksModel.map((blockModel) => {
                if (!blockModel) {
                  return;
                }
                //Si il y a un seul emprunteur OU si seuil non atteint et le block est bon de commande
                return (
                  blockModel?.isVisible && (
                    <div key={blockModel?.name}>
                      <StyledTitle level={2}>{blockModel?.title}</StyledTitle>
                      {blockModel.description && (
                        <StyledDescription>
                          {blockModel.description}
                        </StyledDescription>
                      )}
                      {blockModel.pieceJustificatives &&
                        blockModel.pieceJustificatives.map(
                          (pieceJustificative) =>
                            pieceJustificative?.isVisible && (
                              <BlockOcr
                                key={pieceJustificative?.name}
                                ocrParameters={ocrParameters as IOcr}
                                block={pieceJustificative}
                                borrowerType={
                                  blockModel?.name ===
                                  EEvidenceBlocksTypeNames.BLOCK_COBORROWER
                                    ? personTypes.RoleCd.COBORROWER
                                    : personTypes.RoleCd.BORROWER
                                }
                                onValidate={handleValidate}
                                isAnalyseSending={isAnalyseSending}
                                setIsAnalyseSending={setIsAnalyseSending}
                                isDocumentUploaded={
                                  pieceJustificative?.isValidated
                                }
                                onExpanding={() =>
                                  setSelectedBlock(
                                    pieceJustificative?.name + blockModel?.name
                                  )
                                }
                                doClose={
                                  (selectedBlock?.length ?? 0) > 0 &&
                                  selectedBlock !==
                                    pieceJustificative?.name + blockModel?.name
                                }
                              />
                            )
                        )}
                    </div>
                  )
                );
              })}

            {isParcoursSwitchDevice() ? (
              <StyledButtonFinish
                isDisabled={isAnalyseSending}
                onClick={() => onClickFinishButton()}
              >
                {messages.LABEL_FINISH_BUTTON}
              </StyledButtonFinish>
            ) : (
              <ButtonsFooter
                isBackDisabled={isAnalyseSending}
                // On desactive le bouton suivant lorsqu'une analyse ocr est en cours
                // Sinon, il est toujours actif dans le cas d'un PC,
                // dans le cas contraire (isMobileDevice = true), on a des conditions à respecter pour que le bouton suivant soit actif
                isNextDisabled={
                  isAnalyseSending ||
                  (mandatoryBlockChecked < mandatoryCountBlock &&
                    !ocrKo &&
                    isMobileDevice)
                }
              />
            )}
            <TooltipWrapper>
              <StyledTooltip
                skin="deleteCustomerInfo"
                isVisible
                icon={Icons.TrashIcon}
                hasArrowDown={false}
              >
                <StyledMessage>{messages.WARNING_LABEL}</StyledMessage>
              </StyledTooltip>
            </TooltipWrapper>
          </StyledLoader>
        </Centering>
      )}
    </>
  );
};

export default Documents;
