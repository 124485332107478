import styled, { css } from 'styled-components';
import { SkinType } from './Tooltip';
import { AdviceIcon } from '../Icons';

export const Wrapper = styled.div<{
  isVisible: boolean;
}>`
  overflow: hidden;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  max-height: ${({ isVisible }) => (isVisible ? '999rem' : '0')};
  transition: opacity 0.2s, max-height 0.2s;
`;

const getSkinnedBackgroundColor = ({
  theme,
  skin,
}: {
  theme: any;
  skin: any;
}) => {
  switch (skin) {
    case 'help':
      return theme.variables.colors.chartColors.dark_700;
    case 'warning':
      return theme.variables.colors.chartColors.yellow_300;
    case 'info':
      return theme.vendor.colors.lightest;
    case 'deleteCustomerInfo':
      return theme.vendor.colors.lightest;
    case 'advice':
      return theme.variables.colors.chartColors.blue_100;
    case 'primary':
      return theme.variables.colors.chartColors.white;
    case 'caution':
      return theme.variables.colors.chartColors.grey_50;
    case 'secondary':
      return theme.variables.colors.chartColors.blue_100;
    case 'primaryCymphony':
      return theme.vendor.colors.lightest;
    default:
      return theme.variables.colors.chartColors.white;
  }
};

const getSkinnedCss = ({ skin = 'advice' }) => {
  if (skin === 'help') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.white};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
      line-height: 1.6rem;
      text-align: center;
      padding: 1.2rem 0.8rem 1.2rem 0.8rem;
    `;
  }
  if (skin === 'primary') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
      line-height: 1.6rem;
      text-align: center;
      padding: 0.1rem 0.1rem 0.1rem 0.1rem;
    `;
  }
  if (skin === 'deleteCustomerInfo') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
      line-height: 1.6rem;
      text-align: center;
      padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s}
        ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    `;
  }
  if (skin === 'primaryCymphony') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      border: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
      line-height: 1.6rem;
      text-align: center;
      padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s}
        ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    `;
  }

  return css`
    color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
    font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
    line-height: 2rem;
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s}
      ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  `;
};

export const StyledWrapperWarningMessage = styled.div<{
  skin: SkinType;
  hasArrowDown: boolean;
  isVisible: boolean;
}>`
  ${(skin) => getSkinnedCss(skin)}
  position: relative;
  background-color: ${getSkinnedBackgroundColor};
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${({ hasArrowDown }) => (hasArrowDown ? '0.8rem' : '0')};
  padding-top: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
  padding-bottom: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
  padding-left: 2rem;
  padding-right: 2rem;
  transition: padding 0.2s;

  &::after {
    content: '';
    display: ${({ hasArrowDown }) => (hasArrowDown ? 'block' : 'none')};
    position: absolute;
    bottom: -0.7rem;
    transform: translateX(-50%);
    left: 50%;
    width: 0;
    height: 0;
    margin: auto;
    border-right: 0.8rem solid transparent;
    border-left: 0.8rem solid transparent;
    border-top: 0.8rem solid ${getSkinnedBackgroundColor};
  }
`;

export const StyledWarningMessage = styled.div`
  letter-spacing: 0;
  display: inline-block;
  align-self: center;
  width: 100%;
`;

export const StyledAdviceIcon = styled(AdviceIcon)<{ skin: SkinType }>`
  color: ${({ theme, skin }) => {
    switch (skin) {
      case 'info':
        return theme.vendor.colors.primary;
      case 'advice':
        return theme.variables.colors.interaction.emphasis_700;
      case 'warning':
        return theme.variables.colors.interaction.warning_700;
      case 'primary':
        return theme.variables.colors.chartColors.green_700;
      case 'secondary':
        return theme.variables.colors.chartColors.blue_400;
      default:
        return theme.variables.colors.chartColors.dark_700;
    }
  }};
  margin-top: -0.3rem;
  width: 2.5rem;
  min-width: 2.5rem;
  margin-right: 0.8rem;
`;
