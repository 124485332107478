import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import {
  IImagesFile,
  ImageStatus,
  UploadType,
  IFile,
  FileStatus,
} from '@types';
import { findCorrespondingImage } from '@utils';
import UploadZone from './UploadZone';
import {
  StyledLabel,
  StyledSubText,
  WrapperUploadZone,
  WrapperUploadFileProgress,
  WrapperUpload,
  StyledMultipleUploads,
} from './styles';
import UploadFileProgress from './UploadFileProgress';
import FileViewer from './FileViewer';

export interface IUploadProps {
  label?: string;
  name?: string;
  subText: string;
  fileNbMax: number;
  isMobileDevice: boolean;
  isDisabled?: boolean;
  onFilesUpload: (files: File[]) => void;
  onMaxFilesLimitReached?: () => void;
  onFileDelete: (id: string) => void;
  onCancelFileUpload: (id: string) => void;
  files: IFile[];
  processedImages?: IImagesFile[];
  uploadType?: UploadType;
  willShowUploadZone?: boolean;
}

const UploadComponent = ({
  label,
  name,
  subText,
  fileNbMax = 1,
  isMobileDevice,
  isDisabled = false,
  onFilesUpload = () => {},
  onMaxFilesLimitReached = () => {},
  onFileDelete = () => {},
  onCancelFileUpload = () => {},
  files,
  processedImages,
  uploadType,
  willShowUploadZone = true,
}: IUploadProps) => {
  const { length } = files;
  const off = isDisabled || length >= fileNbMax;
  const isMultiple = fileNbMax > 1;

  // At least one image from the array should be visible
  const isImageStatusVisible = processedImages?.some(
    (processedImage) => processedImage?.status === ImageStatus.VISIBLE
  );

  const isMultipleUpload = uploadType === UploadType.MULTIPLE;

  // In order to show the upload zone, we make sure the files do not already exist nor their corresponding images unless it's a multiple upload
  const showUploadZone =
    (files.length === 0 || !isImageStatusVisible || isMultipleUpload) &&
    willShowUploadZone;

  // In order to show the file Viewer, we make sure that the images are visible and the upload zone is not showing (unless we have a multiple upload)
  const showFileViewer =
    (isMultipleUpload || !showUploadZone) && isImageStatusVisible;

  const dealWithFilesFromFileList = (fileList: FileList): void => {
    const droppedFiles = Array.from(fileList) as File[];
    const remainingFileSlots = fileNbMax - files.length;
    if (droppedFiles.length > remainingFileSlots) {
      // les derniers fichiers ajoutés font dépasser la limite, on ne les upload pas
      onMaxFilesLimitReached();
    } else {
      // on upload les derniers fichiers, sans dépasser la limite
      onFilesUpload(droppedFiles);
    }
  };

  const handleFileChange = (event: any) => {
    dealWithFilesFromFileList(event.target.files);
    event.target.value = '';
  };

  const handleFileDrop = (_: any, monitor: any) => {
    dealWithFilesFromFileList(monitor.getItem().files);
  };

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(item, monitor);
    },
    canDrop() {
      return !off && !isMobileDevice;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      // canDrop: monitor.canDrop(),
    }),
  });

  const handleDelete = (id: any) => {
    onFileDelete(id);
  };

  return (
    <WrapperUpload>
      {label && <StyledLabel>{label}</StyledLabel>}
      {subText && (
        <StyledSubText isMobileDevice={isMobileDevice}>{subText}</StyledSubText>
      )}
      {showFileViewer && !isMultipleUpload && (
        <FileViewer processedImages={processedImages && processedImages[0]} />
      )}
      {!isMultipleUpload && showUploadZone && (
        <WrapperUploadZone ref={drop} isMobileDevice={isMobileDevice}>
          <UploadZone
            isMobileDevice={isMobileDevice}
            isDisabled={off}
            isMultiple={isMultiple}
            onChange={handleFileChange}
            isOver={isOver}
            label={label}
            name={name}
          />
        </WrapperUploadZone>
      )}
      {files.map((file, i) => {
        const index = `${file.id}-${i}`;
        return (
          <StyledMultipleUploads
            key={`multiple-uploads-${index}`}
            isMultiple={isMultiple}
            isLast={i === files.length - 1}
          >
            {showFileViewer && isMultipleUpload && (
              <FileViewer
                key={`file-viewer-${index}`}
                processedImages={findCorrespondingImage(file, processedImages)}
              />
            )}
            <WrapperUploadFileProgress
              key={`wrapper-file-progress-${index}`}
              isMobileDevice={isMobileDevice}
            >
              <UploadFileProgress
                key={`upload-progress-${file.id}`}
                file={file}
                isUploaded={file.status === FileStatus.UPLOADED}
                onCancel={() => onCancelFileUpload(file.id)}
                onDelete={() => handleDelete(file.id)}
              />
            </WrapperUploadFileProgress>
          </StyledMultipleUploads>
        );
      })}
      {isMultipleUpload && showUploadZone && (
        <WrapperUploadZone ref={drop} isMobileDevice={isMobileDevice}>
          <UploadZone
            isMobileDevice={isMobileDevice}
            isDisabled={off}
            isMultiple={isMultiple}
            onChange={handleFileChange}
            isOver={isOver}
            label={label}
            name={name}
          />
        </WrapperUploadZone>
      )}
    </WrapperUpload>
  );
};
const Upload = (props: IUploadProps): React.ReactElement => (
  <UploadComponent {...props} />
);

export default Upload;
