import {
  Icons,
  InformationsBlock,
  Modal,
  ModalContent,
  Loader,
} from '@components';
import {
  StyledSubtitle,
  TextBold,
  TextParagraph,
  StyledH2,
  WrapperTitle,
  WrapperIconText,
  StyledLoader,
} from './styles';
import { QRCodeCanvas } from 'qrcode.react';
import * as messages from './messages';
import { ReactNode, useEffect, useState } from 'react';
import { useRedirection } from '@hooks';
import { selectConfiguration, useAppSelector } from '@store';

export enum EModalState {
  INITIAL = 'initial',
  SWITCH_MOBILE_CONNECTED = 'switchMobileConnected',
  CONFIRMATION_ARRET_PARCOURS = 'confirmationArretParcours',
  TELECHARGEMENT_REUSSI = 'telechargementReussi',
  UNAVAIABLE_SERVICE_OCR = 'unavaibleServiceOCR',
}

export interface IProps {
  url: string;
  isVisible?: boolean;
  isLoading?: boolean;
  onSubmit?: () => void;
  onClickPoursuivre?: () => void;
  onClickArretParcoursSwitchDevice: () => void;
  onClickCancel: () => void;
  onClickTelechargementReussi: () => void;
  setModalState: React.Dispatch<React.SetStateAction<EModalState>>;
  modalState: EModalState;
}

const ModalQRCode: React.FC<IProps> = ({
  url,
  isVisible = false,
  isLoading = false,
  onClickPoursuivre = () => null,
  onClickArretParcoursSwitchDevice = () => null,
  onClickCancel = () => null,
  onClickTelechargementReussi = () => null,
  modalState,
  setModalState,
}) => {
  const onClickPousuivreModal = () => {
    setModalState(EModalState.CONFIRMATION_ARRET_PARCOURS);
    onClickPoursuivre();
  };
  interface IContentMessages {
    isDisplayingQrCode: boolean;
    isDisplayingInfoBulles: boolean;
    subTitle?: string;
    paragraph?: string | ReactNode;
    title?: string;
    icon?: ReactNode;
    buttons: {
      primary?: { label: string; onClick: () => void };
      secondary?: { label: string; onClick: () => void };
    };
  }
  const config = useAppSelector(selectConfiguration);
  const urlFinSouscription = config?.urlFinSouscription;
  const [content, setContent] = useState<IContentMessages | null>(null);
  const { redirigerAccueil } = useRedirection({
    urlFinSouscription: urlFinSouscription,
  });

  useEffect(() => {
    setContent(
      ((): IContentMessages => {
        let contentMessages: IContentMessages = {
          isDisplayingQrCode: false,
          isDisplayingInfoBulles: false,
          buttons: {},
        };
        switch (modalState) {
          case EModalState.INITIAL:
            contentMessages = {
              ...contentMessages,
              title: messages?.TITLE_MODAL_QR_CODE,
              icon: <Icons.Smartphone2Icon />,
              subTitle: messages?.SUBTITLE_MODAL_QR_CODE,
              isDisplayingQrCode: true,
              isDisplayingInfoBulles: true,
              buttons: {
                primary: {
                  label: messages.POURSUIVRE.toUpperCase(),
                  onClick: onClickPousuivreModal,
                },
              },
            };
            break;
          case EModalState.SWITCH_MOBILE_CONNECTED:
            contentMessages = {
              ...contentMessages,
              title: messages.TITLE_MODAL_QR_CODE_SWITCHED,
              icon: <Icons.ArrowBarUpIcon />,
              isDisplayingQrCode: true,
              buttons: {
                primary: {
                  label: messages.POURSUIVRE.toUpperCase(),
                  onClick: onClickPousuivreModal,
                },
              },
            };
            break;
          case EModalState.CONFIRMATION_ARRET_PARCOURS:
            contentMessages = {
              ...contentMessages,
              title: messages.TITLE_MODAL_CONFIRMATION_ARRET_PARCOURS,
              icon: <Icons.WarningIcon />,
              paragraph: (
                <>
                  {
                    messages.TEXT_MODAL_CONFIRMATION_ARRET_PARCOURS_ARRET_PARCOURS
                  }
                  <br />
                  {messages.TEXT_MODAL_CONFIRMATION_ARRET_PARCOURS_CANCEL}
                </>
              ),
              buttons: {
                primary: {
                  label:
                    messages.POURSUIVRE_PC_MODAL_CONFIRMATION_ARRET_PARCOURS.toUpperCase(),
                  onClick: onClickArretParcoursSwitchDevice,
                },
                secondary: {
                  label:
                    messages.POURSUIVRE_MOBILE_MODAL_CONFIRMATION_ARRET_PARCOURS.toUpperCase(),
                  onClick: onClickCancel,
                },
              },
            };
            break;
          case EModalState.TELECHARGEMENT_REUSSI:
            contentMessages = {
              ...contentMessages,
              title: messages.TITLE_MODAL_TELECHARGEMENT_REUSSI,
              icon: <Icons.ArrowBarUpIcon />,
              paragraph: (
                <>
                  {
                    messages.TEXT_MODAL_TELECHARGEMENT_REUSSI_TELECHARGEMENT_REUSSI
                  }
                  <br />
                  {messages.TEXT_MODAL_TELECHARGEMENT_REUSSI_ETAPE_SUIVANTE}
                </>
              ),
              buttons: {
                primary: {
                  label: messages.POURSUIVRE.toLocaleUpperCase(),
                  onClick: onClickTelechargementReussi,
                },
              },
            };
            break;
          case EModalState.UNAVAIABLE_SERVICE_OCR:
            contentMessages = {
              ...contentMessages,
              title: '',
              icon: <Icons.CloudIcon />,
              subTitle: '',
              paragraph: (
                <strong>
                  {messages.TEXT_UNAVAIABLE_SERVICE_THREE_ATTEMPTS}
                </strong>
              ),
              buttons: {
                primary: {
                  label:
                    messages.LABEL_BUTTON_MODAL_UNAVAIABLE_SERVICE_THREE_ATTEMPTS,
                  onClick: onClickArretParcoursSwitchDevice,
                },
              },
            };
            break;
          default:
            break;
        }

        return contentMessages;
      })()
    );
  }, [modalState]);

  return (
    <>
      {content && (
        <Modal isVisible={isVisible}>
          <ModalContent
            withScroll={false}
            icon={content.icon}
            title={
              content.title && (
                <WrapperTitle>
                  <StyledH2>{content.title}</StyledH2>
                </WrapperTitle>
              )
            }
            text={
              <>
                <Loader isLoading={isLoading}>
                  {content.subTitle && (
                    <StyledSubtitle>{content.subTitle}</StyledSubtitle>
                  )}
                  {content.isDisplayingQrCode && url && (
                    <StyledLoader
                      isLoading={
                        modalState === EModalState.SWITCH_MOBILE_CONNECTED
                      }
                    >
                      <QRCodeCanvas
                        id="qrCode"
                        value={url}
                        size={350}
                        bgColor={'#FFFFFF'}
                        level={'L'}
                      />
                    </StyledLoader>
                  )}
                  {content.paragraph && (
                    <TextParagraph>{content.paragraph}</TextParagraph>
                  )}
                  {content.isDisplayingInfoBulles && (
                    <InformationsBlock
                      isVisible
                      skin="info"
                      hasArrowDown={false}
                    >
                      <TextParagraph>
                        <TextBold>
                          {messages?.SUBTITLE_INFOBULLE_COMMENT_CA_MARCHE}
                        </TextBold>
                      </TextParagraph>
                      <WrapperIconText>
                        <Icons.PhoneMessageSendIcon />
                        <TextParagraph>
                          {messages?.TEXT_INFOBULLE_MOBILE}
                        </TextParagraph>
                      </WrapperIconText>
                      <WrapperIconText>
                        <Icons.ScanQRCodeSmallIcon />
                        <TextParagraph>
                          {messages?.TEXT_INFOBULLE_FLASHE}
                        </TextParagraph>
                      </WrapperIconText>
                      <WrapperIconText>
                        <Icons.PictureIcon />
                        <TextParagraph>
                          {messages?.TEXT_INFOBULLE_PHOTO_OU_TELECHARGEZ}
                        </TextParagraph>
                      </WrapperIconText>
                    </InformationsBlock>
                  )}
                </Loader>
              </>
            }
            primaryButtonLabel={content.buttons?.primary?.label}
            onPrimaryButtonClick={content.buttons?.primary?.onClick}
            secondaryButtonLabel={content.buttons?.secondary?.label}
            onSecondaryButtonClick={content.buttons?.secondary?.onClick}
          />
        </Modal>
      )}
    </>
  );
};
export default ModalQRCode;
