import {
  ITarification,
  ProprietaireCompte,
  IInformationPersonnelle,
  IParametresOcr,
  typeAppareil,
} from '@types';

export interface IParameters {
  parametres_switch_device?: IParametresQRcode;
  souscription_externe?: ISouscriptionExterne;
  parametres_offre_commerciale: IParametresOffreCommerciale;
  parametres_emprunteur: IParametresEmprunteur;
  parametres_assurance: IParametresAssurance;
  parametres_ocr: IParametresOcr;
}

export interface IParametresQRcode {
  switch_device_enabled?: boolean;
}

export interface IInformationsSouscription {
  project?: IOffreCommercialeSouscriptionExterne;
  borrower?: IEmprunteurSouscriptionExterne;
  coborrower?: IEmprunteurSouscriptionExterne;
  bank_details?: IBankInformations;
}

export interface IOffreCommercialeSouscriptionExterne {
  goods_code?: string;
  project_amount?: number;
  deposit?: number;
  term?: number;
  renovation_place?: string;
  order_number?: string;
}
export interface ISouscriptionExterne {
  subscription_informations?: IInformationsSouscription;
}

export interface IDocumentsUploaded {
  nomFichier: string[];
  typePiece: string;
  sousTypePiece: string;
  typeEmprunteur:
    | 'EMPRUNTEUR_PRINCIPAL'
    | 'CO_EMPRUNTEUR'
    | 'NO_EMPRUNTEUR_TYPE';
  passageDeContexte: boolean;
  finalisation: boolean;
}

export interface IParametresOffreCommerciale {
  lieux_travaux: IOptionHabitatApi[];
  civilites: IOptionHabitatApi[];
  produits: IProduit[];
  mention_legale: string;
}

export interface IParametresEmprunteur {
  civilites: IOptionHabitatApi[];
  types_piece_identite: IOptionHabitatApi[];
  mention_legale: string;
  situations_logements: IOptionHabitatApi[];
  situations_familiales_mono_emprunteur: IOptionHabitatApi[];
  situations_familiales_co_emprunteur: IOptionHabitatApi[];
  statuts_occupation_locaux: IOptionHabitatApi[];
  statuts_professionnels: IOptionHabitatApi[];
  secteurs_activites: ISecteurActiviteApi[];
  seuil_montant_credit_affichage_infos_employeur: number;
}

export interface IParametresAssurance {
  mention_legale: string;
}

export interface IParametresAssurance {
  mention_legale: string;
}

export interface IParametresRequest {
  numeroSouscription: string;
  apimAccessToken: string;
  authInitToken: string;
  authSwitchDeviceToken?: string;
}

export interface IOffreCommercialeRequest {
  numero_souscription: string;
  offre_commerciale: IOffreCommerciale;
}

export interface IOffreCommerciale {
  produit_id: string;
  categorie_materiel_id: string;
  montant_achat: number;
  montant_apport: number;
  montant_credit: number;
  duree_souhaitee: number;
  tarification_id: string;
  modalite: string;
  report: number;
  lieu_travaux: string;
  numero_bon_de_commande?: string;
  nombre_emprunteurs: string;
}

export interface IEmprunteurRequest {
  numero_souscription: string;
  emprunteur: IEmprunteur;
}

export interface ICoEmprunteurRequest {
  numero_souscription: string;
  coemprunteur: ICoEmprunteur;
}
export interface ITelephone {
  mobile_number?: string;
  home_phone_number?: string;
}
export interface IFamilyExterne {
  marital_status?: string;
  children_nb?: string;
}
export interface IHousingExterne {
  housing_status?: string;
  housing_entry_date?: string;
}
export interface IProfessionalExterne {
  csp?: string;
  beginning_employment_date?: string;
}
export interface IAdresse {
  additional_address?: string;
  city?: string;
  street_address?: string;
  zip_code?: string;
}

export interface IEmprunteurSouscriptionExterne {
  personal_informations?: IInformationPersonnelle;
  family?: IFamilyExterne;
  housing?: IHousingExterne;
  professional?: IProfessionalExterne;
  phone?: ITelephone;
  email?: string;
  address?: IAdresse;
}

export interface IEmprunteur {
  civilite: string;
  prenom: string;
  nom: string;
  nom_naissance: string;
  date_naissance: string;
  pays_naissance: string;
  departement_naissance?: string;
  ville_naissance: string;
  code_insee_naissance?: string;
  nationalite: string;
  type_piece_identite: string;
  numero_piece_identite: string;
  adresse_postale: string;
  complement_adresse?: string;
  code_postal: number;
  ville: string;
  email: string;
  telephone_mobile?: number;
  telephone_fixe?: number;
  situation_familiale: string;
  nombre_enfants: number;
  situation_logement: string;
  date_situation_logement: string;
  revenus_mensuels: number;
  autres_revenus: number;
  charges_residence_principale: number;
  autres_credits: number;
  pensions_alimentaires: number;
  autres_charges: number;
  situation_professionnelle: ISituationProfessionnelle;
}

export interface ICoEmprunteur {
  // NB : ci-dessous, on met en commentaire les champs en comparaison avec l'emprunteur, histoire d'avoir une vision claire
  civilite: string;
  prenom: string;
  nom: string;
  nom_naissance: string;
  date_naissance: string;
  pays_naissance: string;
  departement_naissance?: string;
  ville_naissance: string;
  code_insee_naissance?: string;
  nationalite: string;
  type_piece_identite: string;
  numero_piece_identite: string;
  //adresse_postale: string; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //complement_adresse?: string; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //code_postal: number; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //ville: string; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  email: string;
  telephone_mobile?: number;
  //telephone_fixe?: number; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //situation_familiale?: string; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //nombre_enfants?: number; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //situation_logement?: string; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  //date_situation_logement?: string; // champ de saisie inexistant pour le co-emprunteur -> identique emprunteur côté backend
  revenus_mensuels: number;
  //autres_revenus?: number; // champ de saisie inexistant pour le co-emprunteur
  //charges_residence_principale?: number; // champ de saisie inexistant pour le co-emprunteur
  //autres_credits?: number; // champ de saisie inexistant pour le co-emprunteur
  //pensions_alimentaires?: number; // champ de saisie inexistant pour le co-emprunteur
  //autres_charges?: number; // champ de saisie inexistant pour le co-emprunteur
  //iban?: string; // champ de saisie inexistant pour le co-emprunteur
  //bic?: string; // champ de saisie inexistant pour le co-emprunteur
  //date_ouverture_compte?: string; // champ de saisie inexistant pour le co-emprunteur
  situation_professionnelle: ISituationProfessionnelle;
}

export interface ISituationProfessionnelle {
  secteur_activite_id: string;
  profession_ou_situation_id: string;
  nom_employeur?: string;
  code_postal_professionnel?: string;
  nature_activite?: string;
  numero_SIREN?: string;
  nombre_employes?: number;
  statut_occupation_locaux?: string;
  statut_professionnel?: string;
  date_debut: string;
  type_contrat_id: string;
}

export interface IOptionCategorieMateriel {
  code_materiel: string;
  nom: string;
}

export interface IProduit {
  id: string;
  alpha_code: string;
  libelle: string;
  duree_min: number;
  duree_max: number;
  montant_min: number;
  montant_max: number;
  materiels: IOptionCategorieMateriel[];
  tarifications: ITarification[];
}

export interface IOptionHabitatApi {
  code: string;
  label: string;
}

export interface IInsuranceRequest {
  numero_souscription: string;
  insurance: IInsurance;
}

export interface IInsurance {
  code: string;
  code_bareme: string;
}

export interface IContratPapier {
  contenu: string;
}

export interface IContratElectronique {
  url: string;
  espaceDocumentDematId: string;
}

export interface ISecteurActiviteApi {
  secteur: IOptionHabitatApi;
  professions: IOptionHabitatApi[];
  types_contrat: IOptionHabitatApi[];
}

export interface IUploadDocumentsRequest {
  type_contenu: string;
  fichier: number[];
  nom_fichier: string;
  type_piece: string;
  soustype_piece: string;
  type_emprunteur: string;
}

export interface IUploadDocumentsResponse {
  nom_fichier: string;
  preview_images: HTMLImageElement[];
}

export interface IModifyBorrowerRequest {
  adresse_postale?: string;
  civilite?: string;
  code_postal?: string;
  date_naissance?: string;
  nationalite?: string;
  nom?: string;
  nom_naissance?: string;
  numero_piece_identite?: string;
  prenom?: string;
  type_piece_identite?: string;
  ville?: string;
  ville_naissance?: string;
  email?: string;
  telephoneMobile?: string;
}

export interface IBankInformations {
  iban?: string;
  bic?: string;
  date_ouverture_compte?: string;
  account_holder?: ProprietaireCompte;
  proprietaire_compte?: ProprietaireCompte;
}

export interface IBankInformationsRequest {
  numero_souscription: string;
  isOcr?: boolean;
  bankInformations: IBankInformations;
}

export interface ISouscriptionRequest {
  numero_point_de_vente?: string;
  mode_formation?: boolean;
  fetch_access_token?: boolean;
  apim_access_token?: string;
  auth_init_token?: string;
  marque_blanche?: boolean;
  type_vente?: string;
  request_id: string | undefined;
}

export enum CodesErreurValidationSiren {
  FORMAT_NUMERO_SIREN_KO = 'FORMAT_NUMERO_SIREN_KO',
  NUMERO_SIREN_INVALIDE = 'NUMERO_SIREN_INVALIDE',
  NUMERO_SIREN_VIDE = 'NUMERO_SIREN_VIDE',
}

export interface ILogsRequest {
  niveauLog: string;
  requestId: string;
  numeroSouscription: string;
  device: {};
  logs: {};
}

export interface IEvenementSwitchMobile {
  evenementRealise: string;
  libelleEvenementRealise: string;
  typeAppareil: string;
  dateEvenement: string;
}

export interface IConsultSwitchMobileRequest {
  numero_souscription: string;
  body: IConsultSwitchMobileBody;
}

export interface IConsultSwitchMobileBody {
  typeAppareil: typeAppareil;
  idAppareil: string;
  dateRecuperation?: string;
}

export interface IAjoutSwitchMobileRequest {
  numero_souscription: string;
  body: IConsultSwitchMobileBody;
}
export interface IAjoutSwitchMobileBody {
  evenementRealise: string;
  typeAppareil: typeAppareil;
  idAppareil: string;
  dateEvenement?: string;
}

export interface IControleEmailResponse {
  Code_erreur: number;
  libelle: string;
  message: string;
}

export interface IDocumentsAttendus {
  switch_device_en_cours?: boolean;
  identite_emprunteur: IBlockAttendu;
  revenus_emprunteur: IBlockAttendu;
  domicile_emprunteur: IBlockAttendu;
  domicile_secondaire_emprunteur: IBlockAttendu;
  identite_co_emprunteur: IBlockAttendu;
  revenus_co_emprunteur: IBlockAttendu;
  domicile_co_emprunteur: IBlockAttendu;
  domicile_secondaire_co_emprunteur: IBlockAttendu;
  operation: IBlockAttendu;
  rib: IBlockAttendu;
  bon_de_commande: IBlockAttendu;
}

export interface IBlockAttendu {
  typeDePiece: string;
  attendu: string;
  typeEmprunteur?: string;
  typeBlockDocument: string;
  estUpload: boolean;
  maxFileSize: number;
  maxPdfSize: number;
  maxReloadFile: number;
  maxFilePages: number;
}
