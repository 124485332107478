import { useEffectOnInitApp, useSynchronize } from '@hooks';
import { SynchronizeName } from '@types';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import souscriptionApiSlice from './souscriptionApiSlice';

const useLazyQuerySynchronized = <TStoredData, TRequest, TResponse>({
  synchronizeName,
  useSendMutation,
}: {
  synchronizeName: SynchronizeName;
  useSendMutation: UseLazyQuery<
    QueryDefinition<TRequest, any, never, TResponse>
  >;
}) => {
  const sync = useSynchronize<TStoredData, TRequest, TResponse>({
    synchronizeName,
    useSendMutation,
  });
  useEffectOnInitApp(() => {
    sync?.refreshSynchro();
  }, []);
  return sync;
};

const {
  useLazyGetParametersQuery,
  useLazyGetCountriesQuery,
  useLazyGetDepartmentsQuery,
  useLazyGetDepartmentsByCityQuery,
  useLazyGetCitiesQuery,
  useLazyGetCitiesByZipCdQuery,
  useLazyGetRecapitulatifQuery,
  useLazyGetDocumentsSynchroQuery,
  useLazyGetInsurancesBorrowerQuery,
  useLazyGetInsurancesCoBorrowerQuery,
  useLazyGetScoreQuery,
  useLazyGetValidationSirenNumberQuery,
  useLazyGetAuthCheckQuery,
  useLazyGetEmprunteurInformationsQuery,
  useLazyGetCoEmprunteurInformationsQuery,
  useLazyGetBankInformationsQuery,
  useLazyGetTokenDebranchementQRQuery,
  useLazyGetControleEmailQuery,
} = souscriptionApiSlice;

const useLazyGetParametersQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_PARAMETERS_QUERY,
    useSendMutation: useLazyGetParametersQuery,
  });
const useLazyGetCountriesQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_COUNTRIES_QUERY,
    useSendMutation: useLazyGetCountriesQuery,
  });
const useLazyGetDepartmentsQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_DEPARTMENTS_QUERY,
    useSendMutation: useLazyGetDepartmentsQuery,
  });
const useLazyGetDepartmentsByCityQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_DEPARTMENTS_BY_CITY_QUERY,
    useSendMutation: useLazyGetDepartmentsByCityQuery,
  });
const useLazyGetCitiesQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_CITIES_QUERY,
    useSendMutation: useLazyGetCitiesQuery,
  });
const useLazyGetCitiesByZipCdQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_CITIES_BY_ZIPCD_QUERY,
    useSendMutation: useLazyGetCitiesByZipCdQuery,
  });
const useLazyGetRecapitulatifQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_RECAPITULATIF_QUERY,
    useSendMutation: useLazyGetRecapitulatifQuery,
  });
const useLazyGetDocumentsSynchroQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_DOCUMENTS_SYNCHRO_QUERY,
    useSendMutation: useLazyGetDocumentsSynchroQuery,
  });
const useLazyGetInsurancesBorrowerQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_INSURANCES_BORROWER_QUERY,
    useSendMutation: useLazyGetInsurancesBorrowerQuery,
  });
const useLazyGetInsurancesCoBorrowerQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_INSURANCES_COBORROWER_QUERY,
    useSendMutation: useLazyGetInsurancesCoBorrowerQuery,
  });
const useLazyGetScoreQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_SCORE_QUERY,
    useSendMutation: useLazyGetScoreQuery,
  });
const useLazyGetValidationSirenNumberQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_VALIDATION_SIREN_NUMBER_QUERY,
    useSendMutation: useLazyGetValidationSirenNumberQuery,
  });
const useLazyGetAuthCheckQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_AUTH_CHECK_QUERY,
    useSendMutation: useLazyGetAuthCheckQuery,
  });
const useLazyGetEmprunteurInformationsQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_EMPRUNTEUR_INFORMATIONS_QUERY,
    useSendMutation: useLazyGetEmprunteurInformationsQuery,
  });
const useLazyGetCoEmprunteurInformationsQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_COEMPRUNTEUR_INFORMATIONS_QUERY,
    useSendMutation: useLazyGetCoEmprunteurInformationsQuery,
  });
const useLazyGetBankInformationsQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_BANK_INFORMATIONS_QUERY,
    useSendMutation: useLazyGetBankInformationsQuery,
  });
const useLazyGetTokenDebranchementQRQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_ID_DEBRANCHEMENT_QR_QUERY,
    useSendMutation: useLazyGetTokenDebranchementQRQuery,
  });
const useLazyGetControleEmailQuerySynchronized = () =>
  useLazyQuerySynchronized({
    synchronizeName: SynchronizeName.GET_CONTROLE_EMAIL_QUERY,
    useSendMutation: useLazyGetControleEmailQuery,
  });

export {
  useLazyGetParametersQuerySynchronized,
  useLazyGetCountriesQuerySynchronized,
  useLazyGetDepartmentsQuerySynchronized,
  useLazyGetDepartmentsByCityQuerySynchronized,
  useLazyGetCitiesQuerySynchronized,
  useLazyGetCitiesByZipCdQuerySynchronized,
  useLazyGetRecapitulatifQuerySynchronized,
  useLazyGetDocumentsSynchroQuerySynchronized,
  useLazyGetInsurancesBorrowerQuerySynchronized,
  useLazyGetInsurancesCoBorrowerQuerySynchronized,
  useLazyGetScoreQuerySynchronized,
  useLazyGetValidationSirenNumberQuerySynchronized,
  useLazyGetAuthCheckQuerySynchronized,
  useLazyGetEmprunteurInformationsQuerySynchronized,
  useLazyGetCoEmprunteurInformationsQuerySynchronized,
  useLazyGetBankInformationsQuerySynchronized,
  useLazyGetTokenDebranchementQRQuerySynchronized,
  useLazyGetControleEmailQuerySynchronized,
};
