import ProgressBar from '../ProgressBar';
import { IFile, FileStatus } from '@types';
import UploadedFile from './UploadedFile';
import {
  StyledUploadFileProgress,
  WrapperProgress,
  ErrorMessage,
} from './styles';
import { useEffect, useState } from 'react';

export interface IUploadFileProgressProps {
  file: IFile;
  isUploaded: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
}

const UploadFileProgress = ({
  file,
  isUploaded,
  onCancel = () => ({}),
  onDelete = () => ({}),
}: IUploadFileProgressProps): React.ReactElement => {
  const { error: errorMessage, status } = file;
  const hasError = status === FileStatus.FAILED;
  const [progress, setProgress] = useState(0);

  const RATIO_SMOOTH = 0.8; // Ratio de lissage => 0 = pas de lissage et 1 lisse toute la barre
  const SMOOTH_TIME_IN_MILLIS = 8000; // Temps étalon de lissage pour arriver au ratio de la progress bar

  const calcProgress = () => {
    if (file.progress >= 100) {
      return 100;
    }
    const fromMilliseconds = Date.now() - file.startUploadTimestamp;
    const smoothy =
      (fromMilliseconds < SMOOTH_TIME_IN_MILLIS
        ? fromMilliseconds
        : SMOOTH_TIME_IN_MILLIS) /
      (SMOOTH_TIME_IN_MILLIS / (RATIO_SMOOTH * 100));
    const smoothyProgress = file.progress * (1 - RATIO_SMOOTH) + smoothy;
    return smoothyProgress < 100 ? smoothyProgress : 100;
  };

  useEffect(() => {
    if (file.progress >= 100 || progress >= 100) {
      return;
    }
    setTimeout(() => {
      setProgress(calcProgress());
    }, 100);
  }, [, file.progress, progress]);

  return (
    <StyledUploadFileProgress>
      <UploadedFile
        isCancellable={hasError}
        isDeletable={file.progress === 100 && !hasError}
        isLoaded={file.progress === 100 && !hasError}
        isUploaded={isUploaded}
        fileName={file.name}
        onCancel={onCancel}
        onDelete={onDelete}
      />
      <WrapperProgress>
        <ProgressBar
          progress={file.progress >= 100 ? 100 : progress}
          hasFailed={hasError}
        />
      </WrapperProgress>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </StyledUploadFileProgress>
  );
};

export default UploadFileProgress;
