import { useEffectOnInitApp, useSynchronize } from '@hooks';
import { EvidenceType, SynchronizeName } from '@types';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import souscriptionApiSlice from './souscriptionApiSlice';
import { ETypeEmprunteurUploaded } from 'containers/Documents/useBlocksState';

const useUpdateSynchronized = <TStoredData, TRequest, TResponse>({
  synchronizeName,
  getStoredData,
  validate,
  validateStoreSynchronization,
  useSendMutation,
  blockName,
  borrowerType,
}: {
  // Clé d'identification de l'appel de synchro => obligatoire (Ex. patch enmprunteur de la page ou patch coemprunteur de la page info)
  synchronizeName: SynchronizeName;
  // Elément stocké dans le store (par ex. emprunteur du slice person lors de l'update de l'emprunteur)
  getStoredData?: () => TStoredData;
  // Vérification pendant synchro: Règles de validation de la réponse de l'appel (par exemple la date du RIB identique entre l'appel et la réponse)
  validate?: ({
    request,
    response,
  }: {
    request?: TRequest;
    response?: TResponse;
  }) => boolean;
  // Vérification post synchro: Règles de validation entre l'objet mémorisé et la réponse (par exemple emprunteur dans le slice Person et la réponse dans le slice de synchronisation)
  validateStoreSynchronization?: (args: {
    storedData: TStoredData;
    response: TResponse;
  }) => boolean;
  // hook initial d'envoi/réception API
  useSendMutation: UseMutation<
    MutationDefinition<TRequest, any, any, TResponse>
  >;
  optionalId?: string;
  blockName?: EvidenceType | string;
  borrowerType?: ETypeEmprunteurUploaded | string;
}) => {
  const sync = useSynchronize<TStoredData, TRequest, TResponse>({
    synchronizeName,
    getStoredData,
    validate,
    validateStoreSynchronization,
    useSendMutation,
  });

  const refreshSynchro = ({
    blockName: refreshBlockName,
    borrowerType: refreshBorrowerType,
  }: {
    blockName?: string;
    borrowerType?: string;
  }) => {
    if (
      blockName &&
      borrowerType &&
      (blockName !== refreshBlockName ||
        (blockName !== EvidenceType.JBAN &&
          blockName !== EvidenceType.JDOP &&
          borrowerType !== refreshBorrowerType))
    ) {
      return;
    }
    return sync.refreshSynchro();
  };

  useEffectOnInitApp(() => {
    refreshSynchro({ blockName, borrowerType });
  }, []);

  return { ...sync, refreshSynchro };
};

const {
  useSendAnalyseDocumentMutation,
  useSendModifyBorrowerMutation,
  useSendBankInformationsMutation,
} = souscriptionApiSlice;

const useSendAnalyseDocumentMutationSynchronized = <
  TStoredData,
  TRequest,
  TResponse
>({
  getStoredData,
  validate,
  validateStoreSynchronization,
  blockName,
  borrowerType,
}: {
  getStoredData?: () => TStoredData;
  validate?: ({
    request,
    response,
  }: {
    request?: TRequest;
    response?: TResponse;
  }) => boolean;
  validateStoreSynchronization?: (args: {
    storedData: TStoredData;
    response: TResponse;
  }) => boolean;
  blockName: EvidenceType | string;
  borrowerType: ETypeEmprunteurUploaded | string;
}) =>
  useUpdateSynchronized({
    synchronizeName:
      blockName === EvidenceType.JBAN || blockName === EvidenceType.JDOP
        ? // @ts-ignore
          SynchronizeName['SEND_DOCUMENT_ANALYZE_SYNC_' + blockName]
        : // @ts-ignore
          SynchronizeName[
            'SEND_DOCUMENT_ANALYZE_SYNC_' + blockName + '_' + borrowerType
          ],
    // @ts-ignore prévoir un retour du backend qui retourne l'élément sauvegardé et non un retour vide
    useSendMutation: useSendAnalyseDocumentMutation,
    getStoredData,
    validate,
    validateStoreSynchronization,
  });

const useSendEmprunteurMutationSynchronized = <
  TStoredData,
  TRequest,
  TResponse
>({
  getStoredData,
  validate,
  validateStoreSynchronization,
  blockName,
  borrowerType,
}: {
  getStoredData?: () => TStoredData;
  validate?: ({
    request,
    response,
  }: {
    request?: TRequest;
    response?: TResponse;
  }) => boolean;
  validateStoreSynchronization?: (args: {
    storedData: TStoredData;
    response: TResponse;
  }) => boolean;
  blockName?: EvidenceType | string;
  borrowerType?: ETypeEmprunteurUploaded | string;
}) => {
  let syncName = SynchronizeName.SEND_DOCUMENT_EMPRUNTEUR_SYNC;
  if (blockName && borrowerType) {
    syncName = (syncName +
      '_' +
      blockName +
      '_' +
      borrowerType) as unknown as SynchronizeName;
  }
  const updateSynchronized = useUpdateSynchronized({
    synchronizeName: syncName,
    // @ts-ignore prévoir un retour du backend qui retourne l'élément sauvegardé et non un retour vide
    useSendMutation: useSendModifyBorrowerMutation,
    getStoredData,
    validate,
    validateStoreSynchronization,
  });
  return updateSynchronized;
};

const useSendBankInformationsMutationSynchronized = <
  TStoredData,
  TRequest,
  TResponse
>({
  getStoredData,
  validate,
  validateStoreSynchronization,
}: {
  getStoredData?: () => TStoredData;
  validate?: ({
    request,
    response,
  }: {
    request?: TRequest;
    response?: TResponse;
  }) => boolean;
  validateStoreSynchronization?: (args: {
    storedData: TStoredData;
    response: TResponse;
  }) => boolean;
}) =>
  useUpdateSynchronized({
    synchronizeName: SynchronizeName.SEND_DOCUMENT_BANK_INFORMATIONS_SYNC,
    // @ts-ignore prévoir un retour du backend qui retourne l'élément sauvegardé et non un retour vide
    useSendMutation: useSendBankInformationsMutation,
    getStoredData,
    validate,
    validateStoreSynchronization,
  });

export {
  useSendAnalyseDocumentMutationSynchronized,
  useSendEmprunteurMutationSynchronized,
  useSendBankInformationsMutationSynchronized,
};
