import {
  IBlockAttendu,
  selectConfiguration,
  selectDocumentsSynchronization,
  useAppSelector,
} from '@store';
import { EvidenceType } from '@types';
import { useEffect } from 'react';

const useApplyDocumentSynchro = ({
  blockName,
  borrowerType,
  applyDocumentSynchro,
}: {
  blockName: string;
  borrowerType: 'TIT' | 'CTI' | string;
  applyDocumentSynchro: () => void;
}) => {
  const { isAppReinitialized } = useAppSelector(selectConfiguration);
  const { synchronizedFiles } = useAppSelector(selectDocumentsSynchronization);

  return useEffect(() => {
    if (!synchronizedFiles || !isAppReinitialized) {
      return;
    }
    let synchronizedFileKey = '';
    switch (blockName) {
      case EvidenceType.JDID:
        synchronizedFileKey = 'identite_';
        break;
      case EvidenceType.JDRE:
        synchronizedFileKey = 'revenus_';
        break;
      case EvidenceType.JDDO:
        synchronizedFileKey = 'domicile_';
        break;
      case EvidenceType.JBAN:
        synchronizedFileKey = 'rib';
        break;
      case EvidenceType.JDOP:
        synchronizedFileKey = 'bon_de_commande';
        break;
      case EvidenceType.JDDS:
        synchronizedFileKey = 'domicile_secondaire';
        break;
      default:
        break;
    }
    if (synchronizedFileKey[synchronizedFileKey.length - 1] === '_') {
      synchronizedFileKey +=
        borrowerType === 'TIT' ? 'emprunteur' : 'co_emprunteur';
    }
    // @ts-ignore
    const synchronizedFile = synchronizedFiles[
      synchronizedFileKey
    ] as unknown as IBlockAttendu;
    if (!synchronizedFile || synchronizedFile?.estUpload) {
      return;
    }
    applyDocumentSynchro();
  }, [synchronizedFiles]);
};

export default useApplyDocumentSynchro;
