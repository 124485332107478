const chartColors = {
  green_700: '#3A913F',
  green_500: '#268038',
  green_300: '#83CA87',
  green_cymphony_700: '#1d602a',
  green_050: '#eff8f0',
  dark_700: '#4A4A4A',
  grey_700: '#151515',
  grey_500: '#767676',
  grey_400: '#71787F',
  grey_300: '#B2B2B2',
  grey_200: '#CCCCCC',
  grey_100: '#B1B5B9',
  grey_50: '#F2F2F2',
  grey_10: '#FAFAFA',
  yellow_300: '#fef7ea',
  white: '#FFFFFF',
  black: ' #000000',
  blue_400: '#4A90E2',
  blue_300: '#4a90e240',
  blue_100: '#EEF5FD',
  outlineInput: '#4a90e2',
  red: '#DF3039',
  orange_10: '#FEF7EB',
  neutral_700: '#4B4F54',
  neutral_050: '#F2F3F3',
};

const products = {
  assurance: '#E7208C',
  bnp_ib: '#00965E',
  cmon_projet: '#F48931',
  cpay: '#3A913F',
  credit: '#1AA4D9',
  epargne: '#FBC22D',
};

const interaction = {
  emphasis_700: '#4A90E2',
  error_700: '#FE0000',
  warning_700: '#F5A623',
};

const shadows = {
  15: '0 0.2rem 1rem 0 rgba(0,0,0,0.15)',
  25: '0 0.2rem 2rem 0 rgba(0,0,0,0.15)',
};

const overlays = {
  dark_25: '#0000003F',
};

const colors = {
  chartColors,
  products,
  interaction,
  shadows,
  overlays,
};

export default colors;
