import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useNavigateRouter,
} from 'react-router-dom';
import { getEnv, isDocumentEnabled } from '@utils';
import { selectConfiguration, useAppSelector } from '@store';
import { TypeVente } from '@types';
import { useParcoursType, useRedirection } from '@hooks';
export interface IStep {
  label: string;
  paths: string[];
}

const useNavigate = () => {
  const navigateFromHook = useNavigateRouter();
  const { typeVente } = useAppSelector(selectConfiguration);
  const { pathname: locationPathname, state: stateLocation } = useLocation();
  const { redirigerAccueil } = useRedirection();
  const { isParcoursSwitchDevice } = useParcoursType();

  const navigate: NavigateFunction = (
    pathOrTo: To | number,
    options?: NavigateOptions
  ) => {
    if (isParcoursSwitchDevice() && locationPathname === '/documents') {
      return;
    }
    if (typeof pathOrTo === 'number') {
      navigateFromHook(pathOrTo);
      return;
    }
    navigateFromHook(pathOrTo as To, options);
  };
  const steps: IStep[] = [
    {
      label: 'Offre',
      paths: ['/offre'],
    },
    {
      label: 'Informations',
      paths: ['/informations'],
    },
    {
      label: 'Assurance',
      paths: ['/assurance'],
    },
    {
      label: 'Récapitulatif',
      paths: ['/recapitulatif'],
    },
    {
      label: 'Signature',
      paths: ['/signature/choix', '/signature/verification', '/signature'],
    },
  ];

  if (
    (process.env.REACT_APP_ENABLE_DOCUMENT === 'true' || isDocumentEnabled()) &&
    (typeVente !== TypeVente.VENTE_A_DISTANCE || getEnv() === 'qual')
  ) {
    steps.splice(1, 0, {
      label: 'Documents',
      paths: ['/documents'],
    });
  }
  const getPath = () => (location.hash ?? '').replace('#', '').split('?')[0];

  const getNextStep = (): string | null => {
    const path = getPath();
    let stepIndex = -1;
    let subStepIndex = -1;
    steps.forEach((step, currentStepIndex) => {
      if (step.paths.includes(path)) {
        step.paths.forEach((subPath, currentSubStepIndex) => {
          if (subPath === path) {
            // il existe une sous étape suivante
            if (step.paths.length > currentSubStepIndex + 1) {
              subStepIndex = currentSubStepIndex + 1;
              stepIndex = currentStepIndex;
            }
            //il n'y a pas de sous étape suivante, on passe à la première sous étape de l'étape suivante
            else {
              subStepIndex = 0;
              stepIndex = currentStepIndex + 1;
            }
          }
        });
      }
    });
    if (stepIndex >= 0 && stepIndex < steps.length) {
      return steps[stepIndex].paths[subStepIndex];
    }
    return null;
  };

  const getCurrentStepIndex = (): number => {
    const path = getPath();
    let index = -1;
    steps.forEach((step, currentIndex) => {
      if (step.paths.includes(path)) {
        index = currentIndex + 1;
      }
    });
    return index;
  };

  const navigateBack = () => {
    if (locationPathname === '/offre') {
      redirigerAccueil();
    } else {
      navigate(-1);
    }
  };

  return {
    navigate,
    steps,
    getNextStep,
    getCurrentStepIndex,
    navigateBack,
  };
};
export default useNavigate;
